import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/app/App';
import { polyfill } from './polyfill/polyfill';
import './index.css';

polyfill().then(render);

function render() {
  const container = document.getElementById('app');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
