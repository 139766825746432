import storage, { Keys } from '../shared-logic/storage';
import { QueryStringParams, StringDictionary, User } from '../models';
import { FetchError } from './fetch-error';
import { getApiUrl } from '../integration';

const defaultApiHost =
  process.env.NODE_ENV === 'development'
    ? 'http://nuvola/app_dev.php'
    : 'https://nuvola.madisoft.it';

export const appBaseURL = getApiUrl() || defaultApiHost;
export const apiBaseURL = `${appBaseURL}/api-studente/v1`;
export const API_DEFAULT_LIMIT = 999;

/**
 * Esegue una richiesta al server.
 * @param {RequestInfo} input Fetch API input.
 * @param {RequestInit} init Fetch API init.
 * @returns {Promise<Response>} Server response.
 */
export async function apiClient<T>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> {
  const response = await fetch(input, withDefaultHeaders(init));
  if (response.status === 401) {
    return refreshSessionAndRetry(input, init);
  } else if (!response.ok) {
    const fetchError = new FetchError(response);
    await fetchError.init();
    throw fetchError;
  } else if (response.status === 204) {
    return undefined as T;
  }
  return response.json();
}

/**
 * Esegue una richiesta GET.
 * @param {string} route GET API route.
 * @returns {Promise<Object>} Server response.
 */
export function apiGet<T>(route: string): Promise<T> {
  return apiClient(buildApiURL(route), { method: 'GET' });
}

/**
 * Esegue una richiesta POST.
 * @param {string} route POST API route.
 * @param {Object} body Request body.
 * @returns {Promise<Object>} Server response.
 */
export function apiPost<TResult>(
  route: string,
  body?: BodyInit,
): Promise<TResult> {
  return apiClient<TResult>(buildApiURL(route), {
    method: 'POST',
    body,
  });
}

/**
 * Trasforma i dati in FormData ed esegue una richiesta POST.
 * @param {string} route POST API route.
 * @param {Object} body Request body.
 * @returns {Promise<Object>} Server response.
 */
export function apiPostFormData<TSource, TResult>(
  route: string,
  body?: TSource,
): Promise<TResult> {
  const data = body ? toFormData(body) : undefined;
  return apiPost(route, data);
}

export function apiPut<TSource, TResult>(
  route: string,
  body?: TSource,
): Promise<TResult> {
  const options: RequestInit = {
    method: 'PUT',
  };
  if (body) {
    options.body = JSON.stringify(body);
    options.headers = {
      'Content-Type': 'application/json',
    };
  }
  return apiClient<TResult>(buildApiURL(route), options);
}

export function apiDelete<T>(route: string): Promise<T> {
  return apiClient<T>(buildApiURL(route), { method: 'DELETE' });
}

export function toFormData<T extends Record<string, unknown>>(
  obj: T,
): FormData {
  const data = new FormData();
  Object.entries(obj).forEach(([prop, value]) => {
    if (value !== undefined) {
      data.append(prop, value as string | Blob);
    }
  });

  return data;
}

export function previewFile(
  route: string,
  filename: string,
  isAbsoluteUrl = false,
): Promise<void> {
  return fetchFile(route, filename, isAbsoluteUrl, true);
}

export function downloadFile(
  route: string,
  filename: string,
  isAbsoluteUrl = false,
): Promise<void> {
  return fetchFile(route, filename, isAbsoluteUrl);
}

async function fetchFile(
  route: string,
  filename: string,
  isAbsoluteUrl = false,
  inline = false,
): Promise<void> {
  const url = isAbsoluteUrl ? route : buildApiURL(route);
  const response = await fetch(url, {
    headers: getDefaultHeaders(),
  });
  if (!response.ok) {
    await throwFetchError(response);
  }
  const blob = await response.blob();
  saveBlob(blob, getBlobName(response, filename), inline);
}

export async function downloadBlob(url: string): Promise<Blob> {
  if (!url) throw new Error('Blob URL cannot be empty');
  const response = await fetch(url, {
    headers: getDefaultHeaders(),
  });
  if (!response.ok) {
    await throwFetchError(response);
  }
  return await response.blob();
}

type downloadFromDataArgs = {
  route: string;
  filename: string;
  inline?: boolean;
  data: BodyInit;
};
export async function downloadFromData({
  route,
  filename,
  inline,
  data,
}: downloadFromDataArgs): Promise<void> {
  const url = buildApiURL(route);
  const response = await fetch(url, {
    headers: getDefaultHeaders(),
    method: 'POST',
    body: data,
  });
  const blob = await response.blob();
  saveBlob(blob, getBlobName(response, filename), inline);
}

export function saveBlob(
  blob: Blob,
  filename: string,
  inline?: boolean,
): HTMLElement {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  if (inline) {
    a.target = '_blank';
    a.rel = 'noreferrer';
    a.type = blob.type;
  } else {
    a.download = filename;
  }

  const clickHandler = (): void => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  a.addEventListener('click', clickHandler, false);

  a.click();
  return a;
}

function getBlobName(
  response: Response,
  originalFilename = 'download',
): string {
  // L'header Content-Disposition è definito solo in produzione a causa del CORS
  const contentDisposition = response.headers.get('content-disposition');
  const filename =
    contentDisposition
      ?.split(';')
      .find(cd => cd.includes('filename='))
      ?.replace('filename=', '')
      ?.replace(/"/g, '')
      .trim() || originalFilename;
  return filename;
}

export const refreshSession = async (): Promise<User | null> => {
  try {
    const response = await fetch(buildApiURL('/login-from-web'));
    if (!response.ok) {
      throw new Error(
        'Authentication error: something went wrong refreshing token',
      );
    }
    const user = await response.json();
    storage.setObject(Keys.user, user);
    return user;
  } catch (err) {
    return null;
  }
};

async function refreshSessionAndRetry<T>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> {
  await refreshSession();
  const response = await fetch(input, withDefaultHeaders(init));
  if (!response.ok) {
    await throwFetchError(response);
  }
  return response.json();
}

async function throwFetchError(response: Response): Promise<void> {
  const fetchError = new FetchError(response);
  await fetchError.init();
  throw fetchError;
}

function withDefaultHeaders(init?: RequestInit): RequestInit {
  return {
    ...init,
    headers: {
      ...init?.headers,
      ...getDefaultHeaders(),
    },
  };
}

function getDefaultHeaders(): StringDictionary {
  const user = storage.getObject<{ token: string }>(Keys.user);
  const headers: StringDictionary = {};
  if (user?.token) {
    headers['Authorization'] = `Bearer ${user.token}`;
  }
  return headers;
}

export function buildApiURL(route = ''): string {
  const path = route.charAt(0) === '/' ? route.substring(1) : route;
  return `${apiBaseURL}/${path}`;
}

export function getLinkWithAuth(link: string): string {
  const user = storage.getObject<{ token: string }>(Keys.user);
  const url = new URL(link);
  url.searchParams.append('bearer', user!.token);

  return url.href;
}

export function buildQueryString(params?: QueryStringParams): string {
  if (!params) {
    return '';
  }
  return Object.keys(params)
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key]!)}`,
    )
    .join('&');
}
